import { Campaign } from '@/services/api/campaignV2/campaign'
import { Pledge } from '@/services/api/pledge'
import config from '../../../whiteLabel-config.json'
import { Domain } from '@/services/api/siteAssets/types'

export const isWhiteLabel = (domain: Domain) =>
  !!config[domain as keyof typeof config]

export const getDomainFromSlug = (slug: string): Domain => {
  let domain: Domain = 'invest.angel.com'

  const entries = Object.entries(config)
  for (let i = 0; i < entries.length; i += 1) {
    const [entryDomain, slugs] = entries[i]
    if (slugs.includes(slug)) {
      domain = entryDomain as Domain
      break
    }
  }

  return domain
}

export const getDomainFromHostname = (hostname: string): Domain => {
  const davidHostnames = [
    'david-reg-a.dev.angel.com',
    'david-reg-a.stg.angel.com',
    'thedavidmovie.com',
  ]

  if (hostname.startsWith('david.') || davidHostnames.includes(hostname)) {
    return 'thedavidmovie.com'
  }

  return 'invest.angel.com'
}

export const isAllowed = (obj: Campaign | Pledge, domain: Domain) => {
  const whiteLabelCampaigns = config[domain as keyof typeof config] || null
  const slug = 'slug' in obj ? obj.slug : obj.campaign

  return whiteLabelCampaigns
    ? whiteLabelCampaigns.includes(slug)
    : !Object.values(config).flat().includes(slug)
}
